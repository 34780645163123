var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getRootMenuItems.length > 0)?_c('div',{staticClass:"nav-menu-mobile"},[_c('div',{ref:"scrollContainer",staticClass:"nav-menu-mobile__container",class:{ 'nav-menu-mobile__container--active': _vm.active }},_vm._l((_vm.getAllNavEntries),function(entry){return _c('div',{key:entry.sys.id,ref:`entry_${entry.sys.id}`,refInFor:true,staticClass:"nav-menu-mobile__nav-entry",class:{
        'nav-menu-mobile__nav-entry--prev': _vm.prevEntryIds.includes(
          entry.sys.id
        ),
        'nav-menu-mobile__nav-entry--next':
          !_vm.prevEntryIds.includes(entry.sys.id) && entry !== _vm.currentEntry
      },attrs:{"id":`entry_${entry.title}`}},[(entry !== _vm.getRootMenu)?_c('div',{staticClass:"nav-menu-mobile__title-section flex--row-start-center"},[_c('div',{staticClass:"nav-menu-mobile__back-arrow-holder flex--col-center-center",on:{"click":() => {
              _vm.prevEntryIds.length > 0
                ? _vm.handleEntryChange(_vm.prevEntryIds.slice(-1)[0])
                : null
            }}},[_c('Chevron',{staticClass:"nav-menu-mobile__back-arrow"})],1),_c('div',{staticClass:"nav-menu-mobile__title text-commerce-heading-2 text--clr-black text--center text--uppercase"},[_vm._v(" "+_vm._s(entry.title)+" ")])]):_vm._e(),_c('div',{staticClass:"nav-menu-mobile__content"},[_c('div',{staticClass:"nav-menu-mobile__items nav-menu-mobile__section nav-menu-mobile__section--underline"},[_c('NavigatonMenuItemsList',{attrs:{"content":entry,"showImages":entry == _vm.getRootMenu},on:{"linkItemClicked":_vm.handleLinkClicked,"navItemClicked":_vm.handleEntryChange}})],1),(entry.favourites)?_c('div',{staticClass:"nav-menu-mobile__favourites nav-menu-mobile__section",class:{
            'nav-menu-mobile__section--underline': entry !== _vm.getRootMenu
          }},[_c('PromoSection',{attrs:{"title":_vm.UI_ELEMENT('nav_favourites_title'),"reference":entry.favourites,"appearance":'card-small'}})],1):_vm._e(),(entry == _vm.getRootMenu)?[_c('div',{staticClass:"nav-menu-mobile__store-control-holder nav-menu-mobile__section"},[_c('div',{staticClass:"text-body-small text--clr-black mb-8"},[_vm._v(" "+_vm._s(_vm.UI_ELEMENT("nav_menu_store_selector_tite"))+" ")]),_c('StoreControl',{staticClass:"nav-menu-mobile__store-control",attrs:{"light":""}})],1),_c('div',{staticClass:"nav-menu-mobile__section nav-menu-mobile__section--underline"},[_c('PromoSection',{attrs:{"title":entry[`promo${1}Title`],"reference":entry[`promo${1}Reference`],"appearance":entry[`promo${1}Appearance`] || ''}})],1)]:[_c('div',{staticClass:"nav-menu-mobile__section nav-menu-mobile__section--underline"},[_c('PromoSection',{key:`promo1_${entry.sys.id}`,attrs:{"title":entry[`promo${1}Title`],"reference":entry[`promo${1}Reference`],"appearance":entry[`promo${1}Appearance`] || ''}})],1),_c('div',{staticClass:"nav-menu-mobile__section nav-menu-mobile__section--split"},[_c('div',{staticClass:"nav-menu-mobile__half-width"},[_c('PromoSection',{attrs:{"title":entry[`promo${2}Title`],"reference":entry[`promo${2}Reference`]}})],1),_c('div',{staticClass:"nav-menu-mobile__half-width"},[_c('PromoSection',{attrs:{"title":entry[`promo${3}Title`],"reference":entry[`promo${3}Reference`]}})],1)])]],2)])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }