<template>
  <div class="nav-menu-mobile" v-if="getRootMenuItems.length > 0">
    <div
      class="nav-menu-mobile__container"
      :class="{ 'nav-menu-mobile__container--active': active }"
      ref="scrollContainer"
    >
      <div
        class="nav-menu-mobile__nav-entry"
        :class="{
          'nav-menu-mobile__nav-entry--prev': prevEntryIds.includes(
            entry.sys.id
          ),
          'nav-menu-mobile__nav-entry--next':
            !prevEntryIds.includes(entry.sys.id) && entry !== currentEntry
        }"
        v-for="entry in getAllNavEntries"
        :key="entry.sys.id"
        :id="`entry_${entry.title}`"
        :ref="`entry_${entry.sys.id}`"
      >
        <div
          class="nav-menu-mobile__title-section flex--row-start-center"
          v-if="entry !== getRootMenu"
        >
          <div
            class="nav-menu-mobile__back-arrow-holder flex--col-center-center"
            @click="
              () => {
                prevEntryIds.length > 0
                  ? handleEntryChange(prevEntryIds.slice(-1)[0])
                  : null
              }
            "
          >
            <Chevron class="nav-menu-mobile__back-arrow" />
          </div>
          <div
            class="nav-menu-mobile__title text-commerce-heading-2 text--clr-black text--center text--uppercase"
          >
            {{ entry.title }}
          </div>
        </div>
        <div class="nav-menu-mobile__content">
          <!-- Items -->
          <div
            class="nav-menu-mobile__items nav-menu-mobile__section nav-menu-mobile__section--underline"
          >
            <NavigatonMenuItemsList
              :content="entry"
              :showImages="entry == getRootMenu"
              @linkItemClicked="handleLinkClicked"
              @navItemClicked="handleEntryChange"
            />
          </div>
          <!-- Favourites -->
          <div
            class="nav-menu-mobile__favourites nav-menu-mobile__section"
            :class="{
              'nav-menu-mobile__section--underline': entry !== getRootMenu
            }"
            v-if="entry.favourites"
          >
            <PromoSection
              :title="UI_ELEMENT('nav_favourites_title')"
              :reference="entry.favourites"
              :appearance="'card-small'"
            />
          </div>
          <template v-if="entry == getRootMenu">
            <!-- ROOT: Store selector, promo banner, newsletter signup form -->
            <div
              class="nav-menu-mobile__store-control-holder nav-menu-mobile__section"
            >
              <div class="text-body-small text--clr-black mb-8">
                {{ UI_ELEMENT("nav_menu_store_selector_tite") }}
              </div>
              <StoreControl light class="nav-menu-mobile__store-control" />
            </div>
            <div
              class="nav-menu-mobile__section nav-menu-mobile__section--underline"
            >
              <PromoSection
                :title="entry[`promo${1}Title`]"
                :reference="entry[`promo${1}Reference`]"
                :appearance="entry[`promo${1}Appearance`] || ''"
              />
            </div>
          </template>
          <template v-else>
            <!-- Promo Section 1 -->
            <div
              class="nav-menu-mobile__section nav-menu-mobile__section--underline"
            >
              <PromoSection
                :key="`promo1_${entry.sys.id}`"
                :title="entry[`promo${1}Title`]"
                :reference="entry[`promo${1}Reference`]"
                :appearance="entry[`promo${1}Appearance`] || ''"
              />
            </div>
            <!-- Promo Section 2 -->
            <div
              class="nav-menu-mobile__section nav-menu-mobile__section--split"
            >
              <div class="nav-menu-mobile__half-width">
                <PromoSection
                  :title="entry[`promo${2}Title`]"
                  :reference="entry[`promo${2}Reference`]"
                />
              </div>
              <!-- Promo Section 3 -->
              <div class="nav-menu-mobile__half-width">
                <PromoSection
                  :title="entry[`promo${3}Title`]"
                  :reference="entry[`promo${3}Reference`]"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GTMAPI from "@/integration/GTMAPI.js"

import NavigationMenuMixin from "./NavigationMenuMixin.js"
import Chevron from "@/assets/chevron-down.svg"
import { mapMutations } from "vuex"

import { lockScroll, unlockScroll } from "@/helpers/ScrollLock"

import StoreControl from "./../../components/Navigation/StoreControl"

export default {
  name: "NavigationMenuMobile",

  mixins: [NavigationMenuMixin],

  components: {
    Chevron,
    StoreControl
  },

  data: () => ({
    prevEntryIds: [],
    isTransitionDefault: true,
    dynamicNavEntries: []
  }),

  mounted() {
    this.resetNav()
  },

  methods: {
    ...mapMutations("ui", ["SET_ROUTE_TRANSITION_STATE"]),

    handleLinkClicked() {
      this.toggleScrollLock(false)
      this.SET_ROUTE_TRANSITION_STATE(true)
    },

    handleEntryChange(entryId) {
      if (!this.prevEntryIds.includes(entryId)) {
        // entry is not further up the tree, so...
        // default direction
        this.isTransitionDefault = true
        // push the existing entry's ID (for now, will always be nav) as last (only!) item in prevEntryIds array
        this.prevEntryIds.push(this.currentEntry.sys.id)
      } else {
        // go back up the tree
        this.isTransitionDefault = false
        const _targetIdIndex = this.prevEntryIds.indexOf(entryId)
        // remove all entries 'downstream' of the new current entry (again, not useful yet but may be in future)
        this.prevEntryIds.splice(_targetIdIndex)
      }
      // set the new entry as currentEntry
      this.currentEntry = this.getAllNavEntries.find(
        entry => entry.sys.id === entryId
      )
      GTMAPI.trackEvent("navigation.menu.loaded", {
        nav_entry_id: this.currentEntry.sys.id,
        nav_entry_title: this.currentEntry.title
      })
    },

    onContentfulData(data) {
      this.currentEntry = data
    },

    resetNav() {
      this.currentEntry = this.getRootMenu
      this.isTransitionDefault = true
      this.prevEntryIds = []
    },

    toggleScrollLock(val) {
      if (
        this.currentEntry &&
        this.$refs[`entry_${this.currentEntry.sys.id}`]
      ) {
        if (val) {
          this.$nextTick(() => {
            this.debug.log(
              "disable scroll!",
              this.$refs[`entry_${this.currentEntry.sys.id}`][0]
            )
            this.$refs[`entry_${this.currentEntry.sys.id}`][0].scroll({
              top: 0,
              behavior: "auto"
            })
            lockScroll(this.$refs[`entry_${this.currentEntry.sys.id}`][0])
          })
        } else {
          this.debug.log("enable scroll!")
          unlockScroll()
        }
      }
    }
  },

  watch: {
    currentEntry: {
      handler: function(val) {
        if (val && this.active) {
          this.$nextTick(() => {
            this.toggleScrollLock(true)
          })
        }
      },
      immediate: true
    },

    active: {
      handler: function(val) {
        this.toggleScrollLock(val)
        this.resetNav()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
$padding-xs: 24px;
$padding-md: 32px;

$title-height-xs: 56px;
$title-height-md: 64px;

.nav-menu-mobile {
  position: absolute;
  width: 100%;
  max-width: 450px;
  overflow: hidden;
  left: 0;
  pointer-events: none;

  top: 0;
  height: 100%;

  &__container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: getcolour(bleach_white);
    pointer-events: all;
    overflow: hidden;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.2s ease;

    &--active {
      transform: translate3d(0, 0, 0);
    }
  }

  &__nav-entry {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transition: transform 0.3s ease;

    &--prev,
    &--next {
      transition: transform 0.3s ease;
    }

    &--prev {
      transform: translateX(-100%);
    }

    &--next {
      transform: translateX(100%);
    }
  }

  &__title-section {
    position: sticky;
    top: 0;
    height: 56px;
    border-bottom: 1px solid getcolour(bleach_grey_90);
    padding: 0 calc(#{$padding-xs} / 2);
    background-color: getcolour(bleach_white);
    z-index: 1;

    @include media-breakpoint-up(md) {
      height: 64px;
      padding: 0 calc(#{$padding-md} / 2);
    }
  }

  &__back-arrow-holder {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  }

  &__back-arrow {
    transform: rotate(90deg);
    width: 16px;
    fill: getcolour(bleach_black);
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
  }

  &__section {
    position: relative;
    width: 100%;
    padding: $padding-xs;

    @include media-breakpoint-up(md) {
      padding: $padding-xs $padding-md;
    }

    &--underline {
      &::after {
        display: block;
        content: "";
        width: calc(100% - (2 * #{$padding-xs}));
        height: 1px;
        background-color: getcolour(bleach_grey_90);
        position: absolute;
        bottom: 0;

        @include media-breakpoint-up(md) {
          width: calc(100% - (2 * #{$padding-md}));
        }
      }
    }

    &--split {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
    }
  }

  &__half-width {
    position: relative;
    flex: 0 1 50%;
    width: 50%;
  }

  &__store-control-holder {
    border-top: 1px solid getcolour(bleach_black);
    background-color: getcolour(bleach_grey_90);
    z-index: 1;
  }
}
</style>
